import React from 'react';

import { Header, Grid } from 'semantic-ui-react';

import CustomerItems from './CustomerItems';
import FeaturedCustomer from './FeaturedCustomer';

import './FeaturedCustomers.css';

export default function FeaturedCustomers() {
  return (
    <div id="featured-customers">
      <Header as="h1">Featured Customers</Header>
      <Grid columns={3} stackable>
        {CustomerItems.map((customerItem) => (
          <Grid.Column>
            <FeaturedCustomer {...customerItem} />
          </Grid.Column>
        ))}
      </Grid>
    </div>
  );
}
