import React from 'react';

import { Grid, Header } from 'semantic-ui-react';

import HowItWorksStep from './HowItWorksStep';

import BasicInfoImg from '../../data/graphics/HowItWorksData.svg';
import FishermanTechImg from '../../data/graphics/HowItWorksTechnology.svg';
import CustomWebsiteImg from '../../data/graphics/HowItWorksWebsite.svg';

import './HowItWorks.css';

export default () => (
  <div id="how-it-works-container">
    <div id="how-it-works">
      <div id="how-it-works-header">
        <Header as="h1" align="center">
          How It Works
        </Header>
      </div>
      <Grid id="how-it-works-content" verticalAlign="middle" stackable>
        <HowItWorksStep
          graphic={BasicInfoImg}
          title="Enter your basic information"
          description="Build your brand by specifying your business name, subtype, personality, and logo."
        />
        <HowItWorksStep
          alternate
          className="desktop"
          graphic={FishermanTechImg}
          title={<>FAST&trade; works its magic</>}
          description="Fisherman Automated Site Technology (FAST) finds all available data about your business and designs a custom website."
        />
        <HowItWorksStep
          className="mobile"
          graphic={FishermanTechImg}
          title={<>FAST&trade; works its magic</>}
          description="Fisherman Automated Site Technology (FAST) finds all available data about your business and designs a custom website."
        />
        <HowItWorksStep
          graphic={CustomWebsiteImg}
          title="Receive your custom website"
          description="We’ll send a link for you to preview the site, select a free domain, and publish your website to the world."
        />
      </Grid>
    </div>
  </div>
);
