import GhettYoTaco from '../../data/images/GhettYoTacoScreenshot.png';
import ColourLounge from '../../data/images/TheColourLoungeScreenshot.png';
import Poke from '../../data/images/ThePokeCo.png';

const CustomerItems = [
  {
    title: 'The Colour Lounge',
    quote: 'Hair styling service in Wixom, Michigan',
    person: '',
    link: 'https://www.colourlounge.net/',
    image: ColourLounge,
  },
  {
    title: 'Ghett Yo Taco',
    quote: 'An urban taqueria in downtown Chandler, Arizona',
    person: '',
    link: 'https://www.ghettyotaco.com/',
    image: GhettYoTaco,
  },
  {
    title: 'The Poke Co',
    quote: 'Poke bowl chain across California',
    person: '',
    link: 'https://www.thepokeco.com/',
    image: Poke,
  },
];

export default CustomerItems;
