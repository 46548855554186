import MobileOptimized from '../../data/graphics/Phone.svg';
import WebHosting from '../../data/graphics/Cloud.svg';
import ContentEditor from '../../data/graphics/Pencil.svg';
import SslSecurity from '../../data/graphics/Security.svg';
import CustomDomain from '../../data/graphics/Person.svg';
import VersionUpdates from '../../data/graphics/Update.svg';

const services = [
  { title: 'Mobile Optimized Design', icon: MobileOptimized },
  { title: 'Web Hosting', icon: WebHosting },
  { title: 'Content Editor', icon: ContentEditor },
  { title: 'SSL Security', icon: SslSecurity },
  { title: 'Custom Domain', icon: CustomDomain },
  { title: 'Version Updates', icon: VersionUpdates },
];

export default services;
