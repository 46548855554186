import React from 'react';

import { Header } from 'semantic-ui-react';

import './FeaturedCustomers.css';

export default function FeaturedCustomers({ title, quote, person, link, image }) {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer" className="featured-customer">
      <div className="featured-customer-image" style={{ backgroundImage: `url('${image}')` }} />
      <div className="featured-customer-content">
        <Header as="h3">{title}</Header>
        <p className="featured-customer-quote">{quote}</p>
        <p className="featured-customer-person">{person}</p>
      </div>
    </a>
  );
}
