import React from 'react';

import { Grid, Header } from 'semantic-ui-react';

import './HomeHero.css';

export default function HomeHero() {
  return (
    <Grid columns={2} id="home-hero" stackable>
      <Grid.Column />
      <Grid.Column verticalAlign="middle">
        <Header as="h1">Hook Customers with a Better Website</Header>
        <p>Get a clean, mobile-friendly website with No Effort.</p>
      </Grid.Column>
    </Grid>
  );
}
