import React from 'react';
import { Grid, Image, Header } from 'semantic-ui-react';

const HowItWorksStepGraphic = ({ graphic }) => (
  <Grid.Column width={4}>
    <Image src={graphic} fluid />
  </Grid.Column>
);

const HowItWorksStepContent = ({ title, description }) => (
  <Grid.Column width={12}>
    <Header as="h2">{title}</Header>
    <p>{description}</p>
  </Grid.Column>
);

const HowItWorksStep = ({ graphic, title, description, className = '', alternate = false }) => (
  <Grid.Row className={className}>
    {!alternate && <HowItWorksStepGraphic graphic={graphic} />}
    <HowItWorksStepContent title={title} description={description} />
    {alternate && <HowItWorksStepGraphic graphic={graphic} />}
  </Grid.Row>
);

export default HowItWorksStep;
