import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Button } from 'semantic-ui-react';
import { Link } from 'gatsby';

import ServiceItems from './ServiceItems';

import './Services.css';

const propTypes = {
  signUpLink: PropTypes.string,
};

const defaultProps = {
  signUpLink: '',
};

export default function Services({ signUpLink }) {
  return (
    <div id="services-container">
      <div id="services-content">
        <h1>What&apos;s Included</h1>
        <p>No upfront or hidden fees. See your website before you pay. Leave at any time.</p>
        <Grid id="services-items" columns={3} textAlign="center">
          {ServiceItems.map(({ title, icon }) => (
            <Grid.Column>
              <img src={icon} alt={title} />
              <div className="services-item-description">{title}</div>
            </Grid.Column>
          ))}
        </Grid>
        {signUpLink && (
          <Link to={signUpLink}>
            <Button primary>
              <h3>Get your website</h3>
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
}

Services.propTypes = propTypes;
Services.defaultProps = defaultProps;
