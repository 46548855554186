import React from 'react';
import { Icon } from 'semantic-ui-react';
import PhilDumontet from '../../data/images/PhilDumontetHeadshot.jpg';
import './Testimonials.css';

export default class Testimonials extends React.Component {
  render() {
    return (
      <div id="testimonials">
        <div id="testimonials-background" />
        <img src={PhilDumontet} alt="Fisherman Customer" />
        <div className="testimonial-quote">
          <Icon name="quote left" />
          <p>
            I've worked with a handful of companies in this space, and I've never experienced a
            group as professional & meticulous as Fisherman. I recommend them to anyone who listens
            -- these Fisherman are a catch!"
          </p>
        </div>
        <div className="testimonial-customer">
          <p>Phil Dumontet</p>
          <p>Whole Sol</p>
        </div>
      </div>
    );
  }
}
