import React from 'react';

import FeaturedCustomers from '../components/FeaturedCustomers';
import HomeHero from '../components/HomeHero/HomeHero';
import HowItWorks from '../components/HowItWorks';
import SEO from '../components/SEO';
import Services from '../components/Services';
import Testimonials from '../components/Testimonials';
import Layout from '../layouts/SiteLayout';

export default () => (
  <Layout>
    <SEO
      title="Fisherman | Hook Customers with a Better Website"
      description="Simply answer a few basic questions and get an affordable custom website for your restaurant. Low cost, low effort, and high quality for your very own website."
    />
    <HomeHero />
    <HowItWorks />
    <Services />
    <FeaturedCustomers />
    <Testimonials />
  </Layout>
);
